import React, { useContext, useState, useEffect } from "react"
import Page from "../components/page"
import { LangContext } from "../hooks/lang.js"

const Services = () => {

  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null
  let [dynamicData, setDynamicData] = useState(null)

  useEffect(() => {
    window.contentfulClient
      .getEntries({ content_type: "pageServices", locale: lang })
      .then(p => {

        let dd = p.items[0].fields
        dd.services = dd.services.map(s => s.fields)
        setDynamicData(dd)
      })
  }, [lang])

  if (dynamicData) {
    return (
      <div className="services">
        <section className="section1">
          <h1>Services</h1>
        </section>
        <section className="section2">
          {dynamicData.services.map(s => (
            <span>{s.nom}</span>
          ))}
        </section>
        <section className="section4">
          <h2>{dynamicData.header}</h2>
        </section>
        <section className="section4">
          <table>
            <tbody>
              <tr>
                <td className="colmn colmn1" align="left" valign="top">
                  <h2>{dynamicData.serviceUnTitre}</h2>
                  <h3>{dynamicData.serviceUnSousTitre}</h3>
                  <p>{dynamicData.serviceUnDescription}</p>
                </td>
                <td className="colmn colmn2" align="left" valign="top">
                  <h2>{dynamicData.serviceDeuxTitre}</h2>
                  <h3>{dynamicData.serviceDeuxSousTitre}</h3>
                  <p>{dynamicData.serviceDeuxDescription}</p>
                </td>
                <td className="colmn colmn3" align="left" valign="top">
                  <h2>{dynamicData.serviceTroisTitre}</h2>
                  <h3>{dynamicData.serviceTroisSousTitre}</h3>
                  <p>{dynamicData.serviceTroisDescription}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    )
  } else {
    return <div>Chargement</div>
  }
}

const ServicesWithPage = props => (
  <Page>
    <Services {...props} />
  </Page>
)
export default ServicesWithPage
